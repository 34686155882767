
.subpage--flight-details .flight-schedule--details .flight-schedule__item { 
  --background: #FAFAFA;
  border-radius: 0 !important; 
  background-image: none;
}

.subpage--flight-details .flight-schedule--details .flight-schedule__item--from { margin-bottom: 20px; }

.subpage--flight-details .flight-schedule--details .flight-schedule__item > ion-label {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  width: 25px;
  height: 73px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.subpage--flight-details .flight-schedule--details .flight-schedule__item--from > ion-label { 
  background-image: url('/assets/airplane-departure.png');
 }

.subpage--flight-details .flight-schedule--details .flight-schedule__item--to > ion-label { 
  background-image: url('/assets/airplane-arrival.png');
 }

.subpage--flight-details .flight-schedule--details .flight-schedule__from-label { font-size: 15px; }

.subpage--flight-details .flight-schedule--details .flight-schedule__from-location { 
  color: #3882D7;
  font-size: 18px; 
}

.subpage--flight-details .flight-schedule--details .flight-schedule__time { 
  color: #333333;
  font-size: 15px; 
}

.subpage--flight-details .btn--s-1.button-outline { 
  --padding-start: 14px;
  --padding-end: 14px;
}

/*------------------------------------------------------------------
 # [Alert - to be transferred to App.css] ::start
 ------------------------------------------------------------------*/

.alert--s-1 .alert-wrapper {
  text-align: center;
  padding: 50px 25px;
  width: 90%;
  max-width: 350px;
  background: #ffffff;

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.alert--s-1 .alert-head {
  margin-bottom: 20px;
  padding-top: 180px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  color: #333333;
}

.alert--s-1.alert--with-icon.icon-lobby-waiting .alert-head {
  min-width: 157px;
  min-height: 190px;
  background: url('/assets/airport-lobby-waiting.svg') center top no-repeat;
}

.alert--s-1 .alert-head .alert-title {
  font-size: 22px;
}

.alert--s-1 .alert-message {
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
  color: #888888;
}

.alert--s-1 .alert-button-inner.sc-ion-alert-md {
  min-width: 180px;
  text-align: center;
  justify-content: center;
}

/*------------------------------------------------------------------
 # [Alert - to be transferred to App.css] ::end
 ------------------------------------------------------------------*/


@media (prefers-color-scheme: light) {
  /*
  * Light Colors
  * -------------------------------------------
  */
  body {
  }

  /*
  * iOS Light Theme
  * -------------------------------------------
  */

  .ios body {
  }

  /*
  * Material Design Light Theme
  * -------------------------------------------
  */

  .md body {
  }
}

@media (prefers-color-scheme: dark) {
  /*
  * Dark Colors
  * -------------------------------------------
  */
  body {
  }

  .alert--s-1 .alert-head .alert-title { color: #333333; }

  /*
  * iOS Dark Theme
  * -------------------------------------------
  */

  .ios body {
  }

  /*
  * Material Design Dark Theme
  * -------------------------------------------
  */

  .md body {
  }
}
