/*------------------------------------------------------------------
 # [Fonts] ::start
 ------------------------------------------------------------------*/

@font-face {
  font-family: "Louis George Cafe Bold";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/louis.george.cafe.bold.ttf");
}

/*------------------------------------------------------------------
 # [Fonts] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Lists] ::start
 ------------------------------------------------------------------*/

.list-ios,
.list-md {
  background: #ffffff;
}

/*------------------------------------------------------------------
 # [Lists] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Links] ::start
 ------------------------------------------------------------------*/

.link {
  color: var(--ion-color-primary);
  font-size: 16px;
  text-decoration: underline;
  line-height: normal;
}

.link:hover {
  color: var(--ion-color-primary-shade);
  text-decoration: none;
}

/*------------------------------------------------------------------
 # [Links] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Buttons] ::start
 ------------------------------------------------------------------*/

/* [Button - Style 1] ::start */
.btn--s-1.button-outline {
  --padding-top: 1px;
  --padding-start: 12px;
  --padding-end: 10px;
  --background-hover: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-hover-opacity: 1;
  --background-focused-opacity: 1;
  --background-activated-opacity: 1;
  --color-hover: #ffffff;
  --color-focused: #ffffff;
  --color-activated: #ffffff;
  --border-width: 2px;
  --border-radius: 20px;
  margin-inline-end: 0;
  margin-bottom: 8px;
  height: 36px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.btn--s-1.button-outline:hover {
  opacity: 1;
}
/* [Button - Style 1] ::end */

/* [Button - Style 2] ::start */
.btn--s-2,
.btn--s-2.alert-button,
.btn--s-2.action-sheet-button {
  --padding-start: 60px;
  --padding-end: 60px;
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary-shade);
  --background-focused: var(--ion-color-primary-shade);
  --background-activated: var(--ion-color-primary-shade);
  --background-hover-opacity: 1;
  --background-focused-opacity: 1;
  --background-activated-opacity: 1;
  --border-radius: 64px;
  --box-shadow: none;
  margin: 15px auto auto;
  background: var(--ion-color-primary);
  color: #ffffff;
  height: 50px;
  max-width: 200px;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  border-radius: 64px;
}

.btn--s-2.action-sheet-button:after {
  background: var(--ion-color-primary);
}
/* [Button - Style 2] ::end */

/* [Button - Style 3] ::start */
.btn--s-3,
.btn--s-3.action-sheet-button {
  --background: #ba0202;
  --padding-start: 60px;
  --padding-end: 60px;
  --background-hover: #ff0000;
  --background-focused: #ff0000;
  --background-activated: #ff0000;
  --background-hover-opacity: 1;
  --background-focused-opacity: 1;
  --background-activated-opacity: 1;
  --button-color-focused: #ffffff;
  --button-color-hover: #ffffff;
  --border-radius: 12px;
  --box-shadow: none;
  height: 55px;
  background: #ba0202;
  color: #ffffff;
  font-size: 21px !important;
  font-weight: 400;
  text-transform: none;
  text-align: center;
  letter-spacing: -0.005em;

  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.btn--s-3.action-sheet-button:after {
  background: #ba0202;
}

.btn--s-3.action-sheet-button .action-sheet-button-inner {
  justify-content: center;
}
/* [Button - Style 3] ::end */

/*------------------------------------------------------------------
 # [Buttons] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Page Header] ::start
 ------------------------------------------------------------------*/

.page-header .page-header__toolbar {
  --ion-toolbar-background: rgba(0, 0, 0, 0.25);
  --padding-top: 25px;
  --padding-bottom: 15px;
  --min-height: 70px;
}

.page-header .page-header__menu-btn {
  margin-left: 15px;
}

.page-header .page-header__toolbar:last-of-type {
  --border-width: 0;
}

/*------------------------------------------------------------------
 # [Page Header] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Ion Page] ::start
 ------------------------------------------------------------------*/

ion-app {
  background: url("/assets/header-bg.png") center top no-repeat;
  width: 100%;
  height: 100%;
  --ion-background-color: transparent;
}

ion-split-pane {
  --side-max-width: none;
}

/*------------------------------------------------------------------
 # [Ion Page] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Page Error] ::start
 ------------------------------------------------------------------*/

.page-error {
  background: url("/assets/bg-01-sm.jpg") no-repeat center top / cover;
}

.page-error__label {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 0;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  width: 85%;
  font-size: 1.4em;
  letter-spacing: -0.03em;
}

.page-error__label span {
  display: block;
  font-size: 4em;
  text-shadow: 5px 4px 1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

/*------------------------------------------------------------------
 # [Page Error] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Back Button] ::start
 ------------------------------------------------------------------*/

.subpage .page-header__menu-btn {
  position: relative;
  left: 10px;
  width: 27px;
  height: 27px;
  min-height: 27px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.subpage .page-header__menu-btn ion-back-button::part(native) {
  position: relative;
  left: -1px;
  color: #ffffff;
}

.subpage .page-header__menu-btn ion-back-button {
  --icon-font-size: 20px;
  width: 22px;
}

.subpage .page-header__menu-btn.md ion-back-button {
  position: relative;
  left: -11px;
}

/*------------------------------------------------------------------
 # [Back Button] ::start
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Page Content] ::start
 ------------------------------------------------------------------*/

.page-content::part(scroll) {
  display: flex;
  flex-direction: column;
}

.page-content__header:after {
  display: none;
}

.page-content__toolbar {
  --background: none;
  text-align: center;
}

.page-content .page-content__toolbar:last-of-type {
  --border-width: 0;
}

.page-content__title {
  padding-inline-start: 0;
  padding-inline-end: 0;
  font-size: 25px;
  font-weight: 400;
}

/*------------------------------------------------------------------
 # [Page Content] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Logo] ::start
 ------------------------------------------------------------------*/

.logo {
  height: 26px;
  width: auto;
}

/*------------------------------------------------------------------
 # [Logo] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Tab Navigation] ::start
 ------------------------------------------------------------------*/

.tab-navigation {
  --ion-tab-bar-color: #4199ff;
}

.tab-navigation .tab-navigation__bar {
  --color-selected: #ffffff;
}

.tab-navigation .tab-navigation__btn {
  max-width: none;
  font-size: 10px;
}

.tab-navigation
  .tab-navigation__btn[tab="home"]
  .tab-navigation__label
  > span:after {
  content: "s";
}

.tab-navigation .tab-navigation__label > span {
  position: relative;
  display: inline-block;
}

.tab-navigation .tab-navigation__label > span[aria-hidden="true"] {
  display: none;
}

.tab-navigation .tab-navigation__icon {
  margin-bottom: 7px;
  font-size: 17px;
}

.tab-navigation .tab-navigation__icon::part(tab-navigation__icon-svg) {
  stroke: none;
}

/*------------------------------------------------------------------
 # [Tab Navigation] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Card - Page Container] ::start
 ------------------------------------------------------------------*/

.card--page-container {
  margin: 0;
  flex: 1 0 auto;

  -webkit-border-top-right-radius: 40px;
  -webkit-border-top-left-radius: 40px;
  -moz-border-radius-topright: 40px;
  -moz-border-radius-topleft: 40px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;

  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card--page-container .card__header {
  padding: 0 0 20px;
  margin: 30px 20px 20px;
  border-bottom: 2px solid var(--ion-color-primary);
}

.card--page-container .card__title {
  color: var(--ion-color-primary);
  font-size: 22px;
  font-weight: 400;
}

.card--page-container .card__content {
  padding: 20px 20px 50px;
}

.card--page-container .card__content > p {
  margin-bottom: 20px;
  color: var(--ion-color-step-200, #414141);
  font-size: 16px;
}

/*------------------------------------------------------------------
 # [Card - Page Container] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Card - Article] ::start
 # [/admin-announcements-details]
 ------------------------------------------------------------------*/

.card--article .card__published-date {
  margin-top: 10px;
  color: var(--ion-color-step-200, #333333);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

/*------------------------------------------------------------------
 # [Card - Article] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Action Sheet] ::start
 ------------------------------------------------------------------*/

.action-sheet--s-1 .action-sheet-container.sc-ion-action-sheet-md {
  margin: 0 10px;
}

.action-sheet--s-1 .action-sheet-wrapper.sc-ion-action-sheet-md {
  margin-bottom: 10px;
}

.action-sheet-group.sc-ion-action-sheet-ios:first-child,
.action-sheet--s-1 .action-sheet-group.sc-ion-action-sheet-md:first-child {
  padding-top: 30px;
  padding-bottom: 20px;
}

.action-sheet--s-1 .action-sheet-group.sc-ion-action-sheet-md {
  background: #ffffff;

  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
}

/* [Header and Sub-header] ::start */
.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-md,
.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-ios {
  margin-bottom: 0;
  height: auto;
  text-align: center;
  text-transform: uppercase;
  color: #3882d7;
  font-size: 20px;
}

.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-ios {
  margin-bottom: 0;
  padding-bottom: 30px;
}

.action-sheet--s-1 .action-sheet-sub-title.sc-ion-action-sheet-ios,
.action-sheet--s-1 .action-sheet-sub-title.sc-ion-action-sheet-md {
  text-transform: none;
  font-size: 20px;
  color: #888888;
}

.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-ios,
.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-md {
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
}

.action-sheet--s-1 .action-sheet-sub-title.sc-ion-action-sheet-md {
  padding-left: 5px;
  padding-right: 5px;
}

.action-sheet--s-1 .action-sheet-sub-title.sc-ion-action-sheet-ios,
.action-sheet--s-2 .action-sheet-sub-title.sc-ion-action-sheet-md {
  font-size: 16px;
  letter-spacing: 0.04em;
}

.action-sheet--s-1 .action-sheet-title.sc-ion-action-sheet-ios {
  padding-right: 20px;
  padding-left: 20px;
}
/* [Header and Sub-header] ::end */

/* [Buttons] ::start */
.action-sheet--s-1 .action-sheet-button.sc-ion-action-sheet-md:hover::after {
  opacity: 0;
}

.action-sheet--s-1
  .action-sheet-btn--logout
  .action-sheet-button-inner.sc-ion-action-sheet-md {
  justify-content: center;
  margin: 0 auto;
  max-width: 200px;
  background: #0b94f6;
  text-transform: uppercase;
  color: #ffffff;

  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
}

.action-sheet--s-1
  .action-sheet-group.action-sheet-group-cancel.sc-ion-action-sheet-md {
  margin-top: 15px;
  background: none;
}
/* [Buttons] ::end */

/*------------------------------------------------------------------
 # [Action Sheet] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Flight Schedule] ::start
 ------------------------------------------------------------------*/

/* [Flight Schedule - Toggler] ::start */
.flight-schedule .flight-schedule__toggler {
  display: inline-flex;
  width: auto;
  padding: 3px;
  background: #ffffff;

  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.flight-schedule .flight-schedule__toggler .flight-schedule__toggler__btn {
  --indicator-color: var(--ion-color-primary);
  --background: #ffffff;
  --color: var(--ion-color-step-400, #666666);
  --color-checked: #ffffff;
  --border-radius: 30px;
  --padding-start: 0;
  --padding-end: 0;

  align-items: center;
  flex-basis: auto;
  height: 20px;
  min-height: 35px;
  min-width: 80px;
  font-size: 18px;
  line-height: 20px;
}

.flight-schedule
  .flight-schedule__toggler
  .flight-schedule__toggler__btn[data-theme="arrivals"] {
  --indicator-color: var(--ion-color-primary-shade);
}
/* [Flight Schedule - Toggler] ::end */

/* [Flight Schedule - Items] ::start */
.flight-schedule {
  font-size: 14px;
}

.flight-schedule .flight-schedule__list {
  width: 100%;
  font-size: inherit;
}

.flight-schedule .flight-schedule__item {
  --padding-top: 5px;
  --padding-start: 10px;
  --padding-bottom: 5px;
  --inner-padding-top: 14px;
  --inner-padding-start: 15px;
  --inner-padding-bottom: 12px;
  --inner-padding-end: 20px;
  --color: #fafafa;
  --inner-border-width: 0;
  --background: #fafafa,
    repeating-linear-gradient(
      to right,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    left top, left bottom, left top, right top, repeat-x, repeat-x, repeat-y,
    repeat-y, 0, 6px 1px, 0, 0;

  background-color: #fafafa;
  background-image: repeating-linear-gradient(
      to right,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      #8ac3e5 0%,
      #8ac3e5 50%,
      transparent 50%,
      transparent 100%
    );
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 0, 6px 1px, 0, 0;

  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.flight-schedule .flight-schedule__item:nth-child(2) {
  -webkit-border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.flight-schedule .flight-schedule__item:last-child {
  background-image: none;
}

.flight-schedule .flight-schedule__row {
  justify-content: center;
}

.flight-schedule .flight-schedule__col:not(:first-child) {
  margin-top: 10px;
}

.flight-schedule .flight-schedule__group:not(:last-child) {
  margin-bottom: 20px;
}

.flight-schedule .flight-schedule__item > ion-label {
  line-height: 1em;
  white-space: normal;
}

.flight-schedule .flight-schedule__number-and-time {
  font-size: inherit;
  line-height: inherit;
}

.flight-schedule .flight-schedule__divider {
  --padding-start: 0;
  --inner-padding-start: 10px;
  --inner-padding-end: 10px;
  --background: var(--ion-color-primary);
  position: relative;
  font-size: 11px;
  color: #ffffff;
  border-bottom: none;
  text-transform: uppercase;

  -webkit-border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.flight-schedule .flight-schedule__divider:before,
.flight-schedule .flight-schedule__divider:after {
  position: absolute;
  top: 0;
  content: " ";
  display: block;
}

.flight-schedule .flight-schedule__divider:before {
  left: 0;
  border-top: 7px solid #ffffff;
  border-right: 8px solid transparent;
}

.flight-schedule .flight-schedule__divider:after {
  right: 0;
  border-top: 7px solid #ffffff;
  border-left: 8px solid transparent;
}

.flight-schedule .flight-schedule__divider[data-theme="arrivals"] {
  --background: var(--ion-color-primary-shade);
}

.flight-schedule .flight-schedule__divider > ion-label {
  margin-top: 9px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.05em;
}

.flight-schedule .flight-schedule__divider > ion-label > i {
  display: inline-block;
  height: 16px;
  color: #73c9fe;
  font-size: 3em;
  font-style: normal;
  vertical-align: middle;
  line-height: 15px;
}

.flight-schedule .flight-schedule__separator {
  color: var(--ion-color-step-700, #b3b3b3);
}

.flight-schedule .flight-schedule__destination {
  margin-bottom: 0;
  color: var(--ion-color-step-450, #7d7d7d);
  font-size: 15px;
}

.flight-schedule .flight-schedule__number {
  color: var(--ion-color-primary);
}

.flight-schedule .flight-schedule__time {
  color: #000000;
}

.flight-schedule .flight-schedule__status {
  display: inline-block;
  margin: auto;
  padding: 0;
  min-width: 105px;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.flight-schedule .flight-schedule__status-departed,
.flight-schedule .flight-schedule__status-landed {
  color: #00852b;
  /* color: var(--ion-color-step-200, #333333); */
}

.flight-schedule .flight-schedule__status-cancelled {
  color: #ff0000;
}
/* [Flight Schedule - Items] ::end */

/*------------------------------------------------------------------
 # [Flight Schedule] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [List - Style 1] ::start
 # [/public-notices]
 # [/admin-announcements]
 ------------------------------------------------------------------*/

.list--s-1 .list__item {
  --inner-padding-top: 35px;
  --inner-padding-bottom: 25px;
  --inner-padding-end: 0px;
  --inner-border-width: 0 0 2px 0;
  --background: #ffffff;
  --background-hover: #ffffff;
  --background-focused: #ffffff;
  --background-activated: #ffffff;
  --border-color: var(--ion-color-primary);
  --ripple-color: #ffffff;
}

.list--s-1 .list__item:first-child {
  --inner-padding-top: 15px;
}

.list--s-1 .list__item:last-child {
  --inner-border-width: 0;
}

.list--s-1 .list__item::part(native) {
  flex-direction: column;
  align-items: flex-start;
}

.list--s-1 .list__published-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  color: var(--ion-color-step-200, #333333);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.list--s-1 .list__title {
  margin-top: 5px;
  margin-right: 40px;
  color: var(--ion-color-primary);
  line-height: normal;
  white-space: normal;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.list--s-1 .list__btn {
  --padding-start: 0;
  --padding-end: 0;
  --background: none;
  --background-hover-opacity: 1;
  --background-focused-opacity: 1;
  --background-activated-opacity: 1;
  --color: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary);
  --color-focused: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary);
  --box-shadow: none;
  margin: 0;
}

/*------------------------------------------------------------------
 # [List - Style 1] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [iOS] ::start
 ------------------------------------------------------------------*/

/* .ios body {} */

/* [Flight Schedule - Items] ::start */
.ios .flight-schedule .flight-schedule__divider {
  font-size: 0.95em;
  font-weight: 400;
}
/* [Flight Schedule - Items] ::end */

/*------------------------------------------------------------------
 # [iOS] ::end
 ------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [MD] ::start
 ------------------------------------------------------------------*/

/* .md body {} */
.header-md::after {
  content: none;
}

/*------------------------------------------------------------------
 # [MD] ::end
 ------------------------------------------------------------------*/

@media (prefers-color-scheme: light) {
  /*
  * Light Colors
  * -------------------------------------------
  */
  body {
  }

  /*
  * iOS Light Theme
  * -------------------------------------------
  */

  .ios body {
  }

  /* .ios .page-header .page-header__toolbar { background: var(--ion-color-primary-shade); } */
  .ios .page-header .page-header__toolbar {
    background: rgba(0, 0, 0, 0.25);
  }
  /* --ion-toolbar-background: rgba(0,0,0, 0.25); */

  /*
  * Material Design Light Theme
  * -------------------------------------------
  */

  .md body {
  }
}

@media (prefers-color-scheme: dark) {
  /*
  * Dark Colors
  * -------------------------------------------
  */
  body {
  }

  /*
  * iOS Dark Theme
  * -------------------------------------------
  */

  .ios body {
  }

  .ios .page-header .page-header__toolbar {
    background: #054aa3;
  }

  .action-sheet--s-1 .action-sheet-group.sc-ion-action-sheet-ios {
    background: #ffffff;
  }

  /*
  * Material Design Dark Theme
  * -------------------------------------------
  */

  .md body {
  }

  .md .page-header .page-header__toolbar {
    --ion-toolbar-background: #054aa3;
  }
}

/*------------------------------------------------------------------
 # [Small Devices (400px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 400px) {
  .page-header .page-header__menu-btn {
    position: absolute;
    left: 15px;
    margin-left: 0;
  }

  .btn--s-1.button-outline {
    font-size: 15px;
  }

  .card--page-container .card__header {
    margin: 40px 30px 20px;
  }

  .card--page-container .card__content {
    padding: 30px 30px 50px;
  }

  /* [Tab Navigation] ::start */
  .tab-navigation .tab-navigation__bar {
    height: 75px;
  }

  .tab-navigation
    .tab-navigation__btn[tab="home"]
    .tab-navigation__label
    > span:after {
    content: none;
  }

  .tab-navigation .tab-navigation__label > span[aria-hidden="true"] {
    display: inline-block;
  }

  .tab-navigation .tab-navigation__icon {
    font-size: 22px;
  }
  /* [Tab Navigation] ::end */

  /* [Flight Schedule - Items] ::start */
  .flight-schedule .flight-schedule__item {
    font-size: 19px;
  }

  .flight-schedule .flight-schedule__row {
    justify-content: space-between;
  }

  .flight-schedule .flight-schedule__col:not(:first-child) {
    margin-top: 0;
  }

  .flight-schedule .flight-schedule__divider {
    font-size: 16px;
  }

  .flight-schedule .flight-schedule__status {
    min-width: 110px;
    font-style: normal;
  }
  /* [Flight Schedule - Items] ::end */
}

/*------------------------------------------------------------------
 # [Small Devices (400px and up)] ::end
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Small Devices (415px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 415px) {
  ion-app {
    background-image: url("/assets/header-bg-lg.png");
    background-position: center -80px;
  }
}

/*------------------------------------------------------------------
 # [Small Devices (415px and up)] ::end
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Small Devices (500px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 500px) {
  /* [Tab Navigation] ::start */
  .tab-navigation .tab-navigation__btn {
    font-size: 12px;
  }
  /* [Tab Navigation] ::end */
}

/*------------------------------------------------------------------
 # [Small Devices (500px and up)] ::end
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Large Devices (769px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 769px) {
  .page-error {
    background-image: url("/assets/bg-01-lg.jpg");
  }
}

/*------------------------------------------------------------------
 # [Large Devices (769px and up)] ::end
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
 # [Large Devices (992px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 992px) {
  ion-app {
    background-size: cover;
  }
}

/*------------------------------------------------------------------
 # [Large Devices (992px and up)] ::end
-------------------------------------------------------------------*/
