.subpage--contact-us-success .subpage__title > ion-icon {
  position: relative;
  top: 4px;
  margin-right: 8px;
}

.subpage--contact-us-success .subpage__label {
  margin-bottom: 5px;
  font-size: 1.5em;
}

.subpage--contact-us-success .subpage__text {
  color: #454545;
  font-size: 0.9em;
}
