
ion-menu ion-toolbar { 
  --background: #023C80 !important; 
  --border-width: 0;
}

ion-menu .menu__header::after,
ion-menu .header-md::after {
  content: none; 
}

ion-menu .menu__list { 
  background: none; 
  border-bottom: none;
}

ion-menu.ios .menu__header-btn { 
  padding-top: 10px; 
  padding-bottom: 20px; 
}

ion-menu .menu__close-btn { 
  width: 70px;
  height: 70px; 
}

ion-menu .menu__close-btn__icon {
  margin-top: 10px;
  margin-left: 0;
  width: 1.8em;
  height: 1.8em;
  color: #FFFFFF;
}

ion-menu .menu__title {
  padding-left: 25px;
  text-align: left;
  font-size: 1.3em;
}

ion-menu .menu__title-wrapper { 
  padding-bottom: 30px;
}

ion-menu .menu__title-wrapper:after { 
  content: ' ';
  display: block;
  margin-top: 10px;
  margin-left: 25px;
  height: 1px;
  width: 82%;
  background-color: #0C5C95;
}

ion-menu .header-ios ion-toolbar:last-of-type { --border-width: 0; }


ion-menu ion-content {
  --padding-start: 25px !important;
  --padding-end: 25px !important;
  --background: #023C80 !important;
}

ion-menu .menu__list ion-item {
  --padding-start: 0;
  --background: none;
  --border-color: transparent;
  --color-hover: #FFFFFF;
  --color-focused: #FFFFFF;
  --color-activated: #FFFFFF;
  color: #0B94F6;
  font-size: 1.2em;
}

ion-menu .menu__list ion-item ion-icon { 
  margin: 2px 6px 0 0; 
  color: #0B94F6;
  font-size: 1em;
}

ion-menu .item.sc-ion-label-ios-h, 
ion-menu .item .sc-ion-label-ios-h {
  padding: 0;
  margin: 0;
  color: #0B94F6;
  font-size: 18px;
}

.hamburger-menu { 
  background: url('/assets/menu.svg') 0 0 no-repeat;
  width: 30px;
  height: 19px;
}

.hamburger-menu::part(native) { display: none; }



@media (prefers-color-scheme: dark) {
  /*
  * Dark Colors
  * -------------------------------------------
  */
  body {
  }

  /*
  * iOS Dark Theme
  * -------------------------------------------
  */

  .ios body {
  }

  /*
  * Material Design Dark Theme
  * -------------------------------------------
  */

  .md body {
  }
}
