

.subpage--contact-us .card--page-container .card__content { padding-top: 50px; }

.subpage--contact-us .card__label { 
  display: block;
  margin: 0 auto 30px;
  text-align: center;
  font-size: 18px; 
}

.contact-us-form ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.contact-us-form .label-stacked.sc-ion-label-ios-h,
.contact-us-form .label-stacked.sc-ion-label-md-h { 
  margin-bottom: 5px;
  color: #3882D7;
  font-size: 18px; 
}

.contact-us-form .item-label-stacked .sc-ion-input-md-h { --padding-start: 10px; }

.contact-us-form .native-textarea.sc-ion-textarea-md { padding-inline-start: 10px; }

.contact-us-form .label-stacked.sc-ion-label-md-h { 
  margin-bottom: 15px;
  font-size: 25px; 
}


.contact-us-form .sc-ion-input-ios-h,
.contact-us-form .item-label-stacked .sc-ion-input-md-h,
.contact-us-form .item-label-stacked .sc-ion-textarea-md-h,
.contact-us-form .item-label-stacked .sc-ion-textarea-ios-h {
  --background: #FAFCFF;
  border: 1px solid #CDE0F5;

  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.contact-us-form .sc-ion-input-ios-h.has-focus,
.contact-us-form .item-label-stacked .sc-ion-input-md-h.has-focus,
.contact-us-form .item-label-stacked .sc-ion-textarea-md-h.has-focus,
.contact-us-form .item-label-stacked .sc-ion-textarea-ios-h.has-focus {
  --background: #D5E3F8;
  border: 1px solid #6BA8ED;
}

.contact-us-form .item-label-stacked .sc-ion-input-ios-h { --padding-start: 10px; }


.contact-us-form .item-interactive.item-has-focus {
  --highlight-background: none;
  --background-focused: none;
}

.contact-us-form .contact-us-form__input.contact-us-form__error-input { border-color: #FF1C1C; }




@media (prefers-color-scheme: light) {
  /*
  * Light Colors
  * -------------------------------------------
  */
  body {
  }

  /* .contact-us-form .item-interactive.item-has-focus .contact-us-form__input { --background: rgba(208, 2, 27, 0.05); } */
  /*  */

  .contact-us-form .contact-us-form__error-text {
    padding: 6px 0;
    color: #FF1C1C;
    font-size: 15px;
  }

  /*
  * iOS Light Theme
  * -------------------------------------------
  */

  .ios body {
  }


  /*
  * Material Design Light Theme
  * -------------------------------------------
  */

  .md body {
  }
}

@media (prefers-color-scheme: dark) {
  /*
  * Dark Colors
  * -------------------------------------------
  */
  body {
  }
  
  .contact-us-form { background: none; }

  .contact-us-form ion-item { --background: none !important; }

  .contact-us-form .item-interactive.item-has-focus .contact-us-form__input {
    --background: #D5E3F8;
    --color: #000000;
  }

  .contact-us-form .contact-us-form__error-text {
    padding: 4px 0;
    background: none;
    color: #FF4E4E;
  }

  .subpage--contact-us .card__label { color: #333333; }


  /*
  * iOS Dark Theme
  * -------------------------------------------
  */

  .ios body {
  }


  /*
  * Material Design Dark Theme
  * -------------------------------------------
  */

  .md body {
  }
}



/*------------------------------------------------------------------
 # [Small Devices (400px and up)] ::start
-------------------------------------------------------------------*/

@media screen and (min-width: 400px) {

  .contact-us-form ion-item {
    --padding-start: 16px;
    --inner-padding-end: 16px;
  }

}

/*------------------------------------------------------------------
 # [Small Devices (400px and up)] ::end
-------------------------------------------------------------------*/
